import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as adreE51ttKZFMeta } from "/app/pages/ad.vue?macro=true";
import { default as _91id_93M00guOQ5aOMeta } from "/app/pages/admin/applications/[id].vue?macro=true";
import { default as indexmSSyKaKzSNMeta } from "/app/pages/admin/applications/index.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as loginjuBiVElJesMeta } from "/app/pages/admin/login.vue?macro=true";
import { default as _91id_93AQ7yd6CN3UMeta } from "/app/pages/admin/loyalty/[id].vue?macro=true";
import { default as indexiESHjKqa9MMeta } from "/app/pages/admin/loyalty/index.vue?macro=true";
import { default as bakeryWIR1RnCTC1Meta } from "/app/pages/departments/bakery.vue?macro=true";
import { default as deliaiQfVZKrGTMeta } from "/app/pages/departments/deli.vue?macro=true";
import { default as floralHdG4OPvWbbMeta } from "/app/pages/departments/floral.vue?macro=true";
import { default as groceryeQ2Di4spMHMeta } from "/app/pages/departments/grocery.vue?macro=true";
import { default as meatDpKP8IC1lIMeta } from "/app/pages/departments/meat.vue?macro=true";
import { default as produceXh5VH1gYOLMeta } from "/app/pages/departments/produce.vue?macro=true";
import { default as seafoodgzdTgdvOjvMeta } from "/app/pages/departments/seafood.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as jobs26I3kohglgMeta } from "/app/pages/jobs.vue?macro=true";
import { default as frozenfoodexQIw5uLW1Meta } from "/app/pages/products/frozenfood.vue?macro=true";
import { default as glutenfreeUTBLL6v0iSMeta } from "/app/pages/products/glutenfree.vue?macro=true";
import { default as orderAVDZkzAep6Meta } from "/app/pages/products/order.vue?macro=true";
import { default as specialtyJjhNpVDJvmMeta } from "/app/pages/products/specialty.vue?macro=true";
import { default as wineeYO7X8LxfGMeta } from "/app/pages/products/wine.vue?macro=true";
import { default as emailsavings2ZTUiqsv8xMeta } from "/app/pages/savings/emailsavings.vue?macro=true";
import { default as scripBvyQrSf9zQMeta } from "/app/pages/savings/scrip.vue?macro=true";
import { default as tourxJsw6AzDbPMeta } from "/app/pages/tour.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "ad",
    path: "/ad",
    component: () => import("/app/pages/ad.vue").then(m => m.default || m)
  },
  {
    name: "admin-applications-id",
    path: "/admin/applications/:id()",
    meta: _91id_93M00guOQ5aOMeta || {},
    component: () => import("/app/pages/admin/applications/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-applications",
    path: "/admin/applications",
    meta: indexmSSyKaKzSNMeta || {},
    component: () => import("/app/pages/admin/applications/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginjuBiVElJesMeta || {},
    component: () => import("/app/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: "admin-loyalty-id",
    path: "/admin/loyalty/:id()",
    meta: _91id_93AQ7yd6CN3UMeta || {},
    component: () => import("/app/pages/admin/loyalty/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-loyalty",
    path: "/admin/loyalty",
    meta: indexiESHjKqa9MMeta || {},
    component: () => import("/app/pages/admin/loyalty/index.vue").then(m => m.default || m)
  },
  {
    name: "departments-bakery",
    path: "/departments/bakery",
    component: () => import("/app/pages/departments/bakery.vue").then(m => m.default || m)
  },
  {
    name: "departments-deli",
    path: "/departments/deli",
    component: () => import("/app/pages/departments/deli.vue").then(m => m.default || m)
  },
  {
    name: "departments-floral",
    path: "/departments/floral",
    component: () => import("/app/pages/departments/floral.vue").then(m => m.default || m)
  },
  {
    name: "departments-grocery",
    path: "/departments/grocery",
    component: () => import("/app/pages/departments/grocery.vue").then(m => m.default || m)
  },
  {
    name: "departments-meat",
    path: "/departments/meat",
    component: () => import("/app/pages/departments/meat.vue").then(m => m.default || m)
  },
  {
    name: "departments-produce",
    path: "/departments/produce",
    component: () => import("/app/pages/departments/produce.vue").then(m => m.default || m)
  },
  {
    name: "departments-seafood",
    path: "/departments/seafood",
    component: () => import("/app/pages/departments/seafood.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/app/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "products-frozenfood",
    path: "/products/frozenfood",
    component: () => import("/app/pages/products/frozenfood.vue").then(m => m.default || m)
  },
  {
    name: "products-glutenfree",
    path: "/products/glutenfree",
    component: () => import("/app/pages/products/glutenfree.vue").then(m => m.default || m)
  },
  {
    name: "products-order",
    path: "/products/order",
    component: () => import("/app/pages/products/order.vue").then(m => m.default || m)
  },
  {
    name: "products-specialty",
    path: "/products/specialty",
    component: () => import("/app/pages/products/specialty.vue").then(m => m.default || m)
  },
  {
    name: "products-wine",
    path: "/products/wine",
    component: () => import("/app/pages/products/wine.vue").then(m => m.default || m)
  },
  {
    name: "savings-emailsavings",
    path: "/savings/emailsavings",
    component: () => import("/app/pages/savings/emailsavings.vue").then(m => m.default || m)
  },
  {
    name: "savings-scrip",
    path: "/savings/scrip",
    component: () => import("/app/pages/savings/scrip.vue").then(m => m.default || m)
  },
  {
    name: "tour",
    path: "/tour",
    component: () => import("/app/pages/tour.vue").then(m => m.default || m)
  }
]