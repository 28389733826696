import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Jozdw60ZsE from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_533RIki58s from "/app/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_client_KZ0f4ARq4r from "/app/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import script_YrMPz5ICtR from "/app/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import strapi_CICqBBANNd from "/app/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import _0_auth_reLdRJDfWy from "/app/auth/plugins/0.auth.ts";
import maska_UHaKf2z1iQ from "/app/plugins/maska.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import vue_toastificaton_qKaPuIPpA9 from "/app/plugins/vue-toastificaton.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_Jozdw60ZsE,
  primevue_plugin_egKpok8Auk,
  plugin_client_533RIki58s,
  plugin_client_KZ0f4ARq4r,
  script_YrMPz5ICtR,
  strapi_CICqBBANNd,
  _0_auth_reLdRJDfWy,
  maska_UHaKf2z1iQ,
  sentry_3AyO8nEfhE,
  vue_toastificaton_qKaPuIPpA9
]